// Link to useDebounce library https://github.com/xnimorz/use-debounce
// Library is copied into the project because it is small one, we don't expect it to have any changes, and also to have less package dependencies in the project and we don't expect it to have any changes;

import useDebounce from "./useDebounce";
import useDebouncedCallback, {
  CallOptions,
  ControlFunctions,
  DebouncedState,
  Options,
} from "./useDebouncedCallback";
import useThrottledCallback from "./useThrottledCallback";

export { useDebounce, useDebouncedCallback, useThrottledCallback };

export type { CallOptions, ControlFunctions, DebouncedState, Options };
