// @ts-nocheck
import { ISearchManagementResponse } from "@customTypes/commonResponses";
import { FILTER } from "./constants";

export const sanitizeSearchManagement = (
  searchManagementRes: ISearchManagementResponse
): ISearchManagement => {
  const searchMangementResult = { ...searchManagementRes };

  // EXPERIENCES
  // Cleares from empty entries
  // searchMangementResult[FILTER.EXPERIENCE.filterType] = searchMangementResult[
  //   FILTER.EXPERIENCE.filterType
  // ].filter((item) => {
  //   if (item[FILTER.EXPERIENCE.strapiName] !== null) {
  //     return item;
  //   }
  // });
  // Changes experiences' "name" field to be "title"
  searchMangementResult[FILTER.EXPERIENCE.filterType].map((item) => {
    item[FILTER.EXPERIENCE.strapiName].title = String(
      item[FILTER.EXPERIENCE.strapiName].name
    );
    // delete item[FILTER.EXPERIENCE.strapiName].name;
    return item;
  });

  // LOCATION TYPES
  // Cleares from empty entries
  // searchMangementResult[FILTER.LOCATION_TYPE.filterType] = searchMangementResult[
  //   FILTER.LOCATION_TYPE.filterType
  // ].filter((item) => {
  //   if (item[FILTER.LOCATION_TYPE.strapiName] !== null) {
  //     return item;
  //   }
  // });
  // Changes location types' "Title" field to be "title"
  searchMangementResult[FILTER.LOCATION_TYPE.filterType].map((item) => {
    item[FILTER.LOCATION_TYPE.strapiName].title = String(
      item[FILTER.LOCATION_TYPE.strapiName].Title
    );
    // delete item[FILTER.LOCATION_TYPE.strapiName].Title;
    return item;
  });

  // ACTIVITY_TYPES
  // Cleares from empty entries
  // searchMangementResult[FILTER.ACTIVITY_TYPE.filterType] = searchMangementResult[
  //   FILTER.ACTIVITY_TYPE.filterType
  // ].filter((item) => {
  //   if (item[FILTER.ACTIVITY_TYPE.strapiName] !== null) {
  //     return item;
  //   }
  // });
  // Changes location types' "Title" field to be "title" and Media
  searchMangementResult[FILTER.ACTIVITY_TYPE.filterType].map((item) => {
    item[FILTER.ACTIVITY_TYPE.strapiName].title = String(
      item[FILTER.ACTIVITY_TYPE.strapiName].Title
    );
    // delete item[FILTER.ACTIVITY_TYPE.strapiName].Title;

    item[FILTER.ACTIVITY_TYPE.strapiName].Media = item[
      FILTER.ACTIVITY_TYPE.strapiName
    ].PrimaryImage
      ? item[FILTER.ACTIVITY_TYPE.strapiName].PrimaryImage[0]
      : null;
    // delete item[FILTER.ACTIVITY_TYPE.strapiName].PrimaryImage;
    return item;
  });

  // ACCOMMODATIONS
  const accommodations = {
    budget: { min: 250, max: 500 },
    hotels: {
      isChecked: false,
      title: "Hotel",
      categories: [],
    },
    rentals: {
      isChecked: false,
      title: "Rental",
      categories: [],
    },
  };

  // searchMangementResult[FILTER.ACCOMMODATIONS.filterType] = searchMangementResult[
  //   FILTER.ACCOMMODATIONS.filterType
  // ].filter((item) => {
  //   if (item[FILTER.ACCOMMODATIONS.strapiName] !== null) {
  //     return item;
  //   }
  // });

  searchMangementResult[FILTER.ACCOMMODATIONS.filterType].forEach((item) => {
    if (item[FILTER.ACCOMMODATIONS.strapiName]) {
      item[FILTER.ACCOMMODATIONS.strapiName].title = String(
        item[FILTER.ACCOMMODATIONS.strapiName].Title
      );
      // delete item[FILTER.ACCOMMODATIONS.strapiName].Title;
      item[FILTER.ACCOMMODATIONS.strapiName].propertyCategory = String(
        item[FILTER.ACCOMMODATIONS.strapiName][
          FILTER.ACCOMMODATIONS.propertyCategory
        ]
      );
      // delete item[FILTER.ACCOMMODATIONS.strapiName][
      //   FILTER.ACCOMMODATIONS.propertyCategory
      // ];
    }
    if (item[FILTER.ACCOMMODATIONS.strapiName].propertyCategory === "Hotel") {
      accommodations.hotels.categories.push(item);
    } else if (
      item[FILTER.ACCOMMODATIONS.strapiName].propertyCategory === "Rental"
    ) {
      accommodations.rentals.categories.push(item);
    }
  });
  searchMangementResult[FILTER.ACCOMMODATIONS.filterType] = accommodations;

  return searchMangementResult;
};
