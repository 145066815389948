import { Dayjs } from "dayjs";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

interface IUseHolidayDurationReturn {
  useDurationRedirect: () => Promise<void>;
  shouldRedirect: boolean;
}

const REDIRECT_DAYS = {
  EXTENDED: 15,
  LONG: 30,
};
const REDIRECT_LINKS = {
  EXTENDED: "/extended-holidays",
  LONG: "/long-term-stays",
};

const useHolidayDuration = (
  startDate: Dayjs | null,
  endDate: Dayjs | null
): IUseHolidayDurationReturn => {
  const router = useRouter();
  const [holidayDuration, setHolidayDuration] = useState<string | number>("");

  useEffect(() => {
    if (startDate && endDate) {
      setHolidayDuration(endDate.diff(startDate, "days"));
    } else {
      setHolidayDuration("");
    }
  }, [startDate, endDate]);

  async function useDurationRedirect(): Promise<void> {
    if (
      holidayDuration >= REDIRECT_DAYS.EXTENDED &&
      holidayDuration < REDIRECT_DAYS.LONG
    ) {
      await router.push(REDIRECT_LINKS.EXTENDED);
    } else if (holidayDuration >= REDIRECT_DAYS.LONG) {
      await router.push(REDIRECT_LINKS.LONG);
    }
  }

  const shouldRedirect = useMemo(() => {
    return Number(holidayDuration) - REDIRECT_DAYS.EXTENDED >= 0;
  }, [holidayDuration]);

  return { useDurationRedirect, shouldRedirect };
};

export default useHolidayDuration;
