import CheckboxCheckedIcon from "@components/pages/package-search/assets/CheckboxCheckedIcon";
import CheckboxUncheckedIcon from "@components/pages/package-search/assets/CheckboxUncheckedIcon";
import { onEnterDown } from "@components/utils/accessibilityHelpers";
import { IQueryItemAccommodation } from "@customTypes/filtersProvider";
import { FiltersContext } from "providers/FiltersProvider";
import { useCallback, useContext } from "react";

interface IAccommodationFilterOption {
  item: IQueryItemAccommodation;
}

const AccommodationFilterOption = ({
  item,
}: IAccommodationFilterOption): JSX.Element => {
  const filtersContext = useContext(FiltersContext);

  const renderCheckboxIcon = useCallback(() => {
    if (item.isChecked) {
      return <CheckboxCheckedIcon />;
    } else {
      return <CheckboxUncheckedIcon />;
    }
  }, [item]);

  const onClickHandler = useCallback(() => {
    item.isChecked
      ? filtersContext?.removeAccommodation(item)
      : filtersContext?.selectAccommodation(item);
  }, [item.isChecked]);

  return (
    <label
      className={`relative flex w-full cursor-pointer flex-row justify-between`}
      tabIndex={0}
      onKeyDown={(e) => onEnterDown(e, onClickHandler)}
    >
      <input
        type="checkbox"
        name={item.title}
        className="absolute top-0 left-0 h-0 w-0 opacity-0"
        onChange={() => onClickHandler()}
        checked={item.isChecked}
        tabIndex={-1}
      />
      <span
        className={`flex cursor-pointer items-center text-p13 text-darkblue`}
      >
        {item.title}
      </span>
      {renderCheckboxIcon()}
    </label>
  );
};

export default AccommodationFilterOption;
