import { IFooterSection } from "@components/footer/FooterSection";
import {
  IFooterTabComponentResponse,
  IImageComponentResponse,
  IMediaComponentResponse,
} from "@customTypes/componentResponses";
import MediaAssets from "./MediaAssets";

/**
 * This file contains a small usefull functions to make our lives easier
 */

export function footerSectionLinks(
  footerTab: IFooterTabComponentResponse[]
): IFooterSection {
  return {
    section: footerTab[0],
    links: footerTab.slice(1),
  };
}

export function ImageDefault(
  media:
    | null
    | undefined
    | IMediaComponentResponse
    | IImageComponentResponse
    | string
): string {
  if (
    typeof media !== "undefined" &&
    media !== null &&
    // eslint-disable-next-line no-prototype-builtins
    media?.hasOwnProperty("url")
  ) {
    // @ts-expect-error
    return media.url;
  } else if (typeof media === "string") {
    return media;
  }
  return MediaAssets.getAsset("no_image_found");
}
