import HorizontalCalendar from "@components/calendar/HorizontalCalendar";
import Button from "@components/common/Button";
import useAppContext from "@hooks/useAppContext";
import { IUseCalendarReturn } from "@hooks/useCalendar";
import { FiltersContext } from "providers/FiltersProvider";
import { useContext } from "react";
import MobileCalendarScreenModal from "./MobileCalendarScreenModal";
import SearchCalendar from "./SearchCalendar";

interface ICalendarStepProps {
  calendarUtils: IUseCalendarReturn;
  isPartialFilter?: boolean;
  tabIndex: number;
}

const CalendarStep = ({
  calendarUtils,
  isPartialFilter,
  tabIndex,
}: ICalendarStepProps): JSX.Element => {
  const appContext = useAppContext();
  const filtersContext = useContext(FiltersContext);

  return appContext?.isMobile ? (
    <>
      {/* MOBILE */}
      {isPartialFilter ? (
        <>
          {/* FIND YOUR TRIP */}
          {/**
          <div className="sticky top-0 z-[99] mx-auto w-fit border-b-[1px] border-blue-20 bg-blue-5 text-darkgray">
            <CustomWeekdayHeader />
          </div>
           */}
          <SearchCalendar
            setCalendarStatus={calendarUtils.setCalendarStatus}
            startDateState={calendarUtils.startDateState}
            endDateState={calendarUtils.endDateState}
            setStartDate={calendarUtils.setStartDate}
            setEndDate={calendarUtils.setEndDate}
            monthsShown={calendarUtils.monthsShown}
            setMonthsShown={calendarUtils.setMonthsShown}
          />
        </>
      ) : (
        <>
          {/* SEARCH TRIP FILTERS */}
          <MobileCalendarScreenModal
            title={"When is your trip?"}
            backHandler={() => {
              calendarUtils.resetToLastValues();
              filtersContext?.closeAccordion(tabIndex);
            }}
            calendarUtils={calendarUtils}
            secondaryButtonHandler={() => {
              calendarUtils.resetValues();
              filtersContext?.changeCalendarSelection(false);
            }}
            primaryButtonHandler={() => {
              filtersContext?.closeAccordion(tabIndex);
            }}
          />
        </>
      )}
    </>
  ) : (
    <>
      {/* DESKTOP */}
      <div
        className={`datepicker-centered mb-3 ${
          calendarUtils.inputDate ? "h-[250px]" : "h-[270px]"
        }`}
      >
        <HorizontalCalendar
          monthsShown={calendarUtils.monthsShown}
          startDateState={calendarUtils.startDateState}
          setStartDate={calendarUtils.setStartDate}
          endDateState={calendarUtils.endDateState}
          setEndDate={calendarUtils.setEndDate}
        />
      </div>
      {calendarUtils.inputDate && (
        <Button
          variant="no-outline"
          onClick={() => {
            calendarUtils.resetValues();
            filtersContext?.changeCalendarSelection(false);
          }}
        >
          Clear&nbsp;Dates
        </Button>
      )}
    </>
  );
};

export default CalendarStep;
