import { IHeaderProps } from "./Header";
import HeaderContactProfile from "./HeaderContactProfile";
import HeaderMenuBar from "./HeaderMenuBar";
import MobileNavModal from "./MobileNavModal";

const HeaderItems = ({
  topNavigation,
  menus,
}: Pick<IHeaderProps, "topNavigation" | "menus">): JSX.Element => {
  return (
    <div className="my-auto flex h-full w-full flex-row items-center justify-between">
      <HeaderMenuBar
        menuItems={
          menus.menus.filter((item) => item.title === "Top menu")[0].items
        }
      />
      <HeaderContactProfile
        phoneAction={"tel:" + topNavigation?.Phone_Action}
        phoneNumber={topNavigation?.Phone_Visible}
        topNavigation={topNavigation}
      />
      <MobileNavModal
        topNavigation={topNavigation}
        menuItems={
          menus.menus.filter((item) => item.title === "Top menu")[0].items
        }
      />
    </div>
  );
};

export default HeaderItems;
