import { FiltersContext } from "providers/FiltersProvider";
import { useContext } from "react";
import AccommodationTypeButton from "./AccommodationTypeButton";

const AccommodationTypeButtons = (): JSX.Element => {
  const filtersContext = useContext(FiltersContext);

  return (
    <div className="flex flex-row gap-2">
      <AccommodationTypeButton
        variant="hotel"
        item={filtersContext?.searchQuery.accommodations.hotels}
      />
      <AccommodationTypeButton
        variant="rental"
        item={filtersContext?.searchQuery.accommodations.rentals}
      />
    </div>
  );
};

export default AccommodationTypeButtons;
