import { FiltersContext } from "@providers/FiltersProvider";
import dayjs from "dayjs";
import { useCallback, useContext } from "react";
import {
  BUDGETS,
  FILTER,
  TAG_TYPES,
  TRAVELERS,
} from "../components/utils/constants";
import { INightsComponentResponse } from "../types/componentResponses";
import {
  IQueryItemAccommodation,
  ISearchManagement,
  ISearchQuery,
} from "../types/filtersProvider";
import { IFilteringEntryResponse } from "../types/modelResponses";

interface IBodyParamsArgs {
  tags?: string | string[];
  activities?: string | string[];
  activityTypes?: string | string[];
  locations?: string | string[];
  locationTypes?: string | string[];
  propertyTypes?: string | string[];
  mainPropertyTypes?: string | string[];
  minBudget?: string | string[];
  maxBudget?: string | string[];
  rooms?: string | string[];
}

interface IUseSearchReturn {
  buildQueryParameters: (
    tags: ISearchQuery,
    searchManagement: ISearchManagement | null
  ) => string;
  getBodyParams: ({
    tags,
    activities,
    activityTypes,
    locations,
    locationTypes,
    propertyTypes,
    mainPropertyTypes,
    minBudget,
    maxBudget,
    rooms,
  }: IBodyParamsArgs) => string;
  convertQueryParamsToTags: (
    {
      tags,
      activities,
      activityTypes,
      locations,
      locationTypes,
      propertyTypes,
    }: IBodyParamsArgs,
    setter: (activeTags: IFilteringEntryResponse[]) => void
  ) => void;
  generateAlgoliaFilters: (
    bodyParamsForFetch: string,
    dates: {
      startDate: string | undefined;
      endDate: string | undefined;
    },
    nights: {
      oneDestination: INightsComponentResponse;
      twoDestination: INightsComponentResponse;
      threeDestination: INightsComponentResponse;
    }
  ) => string;
}

const useSearch = (): IUseSearchReturn => {
  const filtersContext = useContext(FiltersContext);

  const buildQueryParameters = (
    tags: ISearchQuery,
    searchManagement: ISearchManagement | null
  ): string => {
    const clearEntry = (title: string | undefined): string | undefined => {
      if (
        title?.includes("&") ||
        title?.includes(", ") ||
        title?.includes(" ") ||
        title?.includes("-")
      ) {
        return title
          .replaceAll("&", "%26")
          .replaceAll(", ", "_")
          .replaceAll("-", ".")
          .replaceAll(" ", "-");
      } else {
        return title;
      }
    };

    const searchManagementAccommodations =
      searchManagement?.[FILTER.ACCOMMODATIONS.filterType];
    let query = "?";
    for (let [key, value] of Object.entries(tags)) {
      if (key === "accommodations") {
        const accommodationsList = [];
        const mainAccommodationsList = [];
        for (const [subkey] of Object.entries(value)) {
          const tagItem = tags[key][subkey];
          if (subkey === "budget") {
            if (tagItem.min !== BUDGETS.MIN || tagItem.max !== BUDGETS.MAX) {
              query += `${"minBudget"}=${tagItem.min}&${"maxBudget"}=${
                tagItem.max
              }&`;
            }
          } else {
            if (tagItem.isChecked) {
              mainAccommodationsList.push(clearEntry(tagItem.title));
            }
            if (tagItem.isChecked && tagItem.categories.length < 1) {
              searchManagementAccommodations &&
                accommodationsList.push(
                  ...searchManagementAccommodations[subkey].categories.map(
                    (accommodationItem: IQueryItemAccommodation) =>
                      accommodationItem[FILTER.ACCOMMODATIONS.strapiName]
                  )
                );
            } else {
              if (
                searchManagementAccommodations &&
                tagItem.categories.length ===
                  searchManagementAccommodations[subkey].categories.length
              ) {
                mainAccommodationsList.push(`${clearEntry(tagItem.title)}All`);
              }
              accommodationsList.push(...tagItem.categories);
            }
          }
        }
        if (accommodationsList.length > 0) {
          query += `${"propertyTypes"}=${accommodationsList
            .map((tag) => {
              return clearEntry(tag.title);
            })
            .join("+")}&`;
        }
        if (mainAccommodationsList.length > 0) {
          query += `${"mainPropertyTypes"}=${mainAccommodationsList.join(
            "+"
          )}&`;
        }
      }

      if (key === "travelers") {
        if (tags[key].rooms !== TRAVELERS.ROOMS.DEFAULT) {
          query += `rooms=${tags[key].rooms}&`;
        }
      }

      if (value.length > 0) {
        if (key === "experiences") {
          key = "tags";
        }
        query += `${key}=${value
          .map((tag: IFilteringEntryResponse) => {
            return clearEntry(tag.title);
          })
          .join("+")}&`;
      }
    }
    query = query.slice(0, -1);

    return query;
  };

  const getBodyParams = ({
    tags,
    activities,
    activityTypes,
    locations,
    locationTypes,
    propertyTypes,
    mainPropertyTypes,
    minBudget,
    maxBudget,
    rooms,
  }: IBodyParamsArgs): string => {
    return JSON.stringify({
      tags:
        tags && typeof tags === "string"
          ? tags
              .split(" ")
              .map((tag) =>
                tag
                  .replaceAll("_", ", ")
                  .replaceAll("-", " ")
                  .replaceAll(".", "-")
              )
          : undefined,
      activities:
        activities && typeof activities === "string"
          ? activities
              .split(" ")
              .map((tag) =>
                tag
                  .replaceAll("_", ", ")
                  .replaceAll("-", " ")
                  .replaceAll(".", "-")
              )
          : undefined,
      activityTypes:
        activityTypes && typeof activityTypes === "string"
          ? activityTypes
              .split(" ")
              .map((tag) =>
                tag
                  .replaceAll("_", ", ")
                  .replaceAll("-", " ")
                  .replaceAll(".", "-")
              )
          : undefined,
      locations:
        locations && typeof locations === "string"
          ? locations
              .split(" ")
              .map((tag) =>
                tag
                  .replaceAll("_", ", ")
                  .replaceAll("-", " ")
                  .replaceAll(".", "-")
              )
          : undefined,
      locationTypes:
        locationTypes && typeof locationTypes === "string"
          ? locationTypes
              .split(" ")
              .map((tag) =>
                tag
                  .replaceAll("_", ", ")
                  .replaceAll("-", " ")
                  .replaceAll(".", "-")
              )
          : undefined,
      propertyTypes:
        propertyTypes && typeof propertyTypes === "string"
          ? propertyTypes
              .split(" ")
              .map((tag) =>
                tag
                  .replaceAll("_", ", ")
                  .replaceAll("-", " ")
                  .replaceAll(".", "-")
              )
          : undefined,
      mainPropertyTypes:
        mainPropertyTypes && typeof mainPropertyTypes === "string"
          ? mainPropertyTypes
              .split(" ")
              .map((tag) =>
                tag
                  .replaceAll("_", ", ")
                  .replaceAll("-", " ")
                  .replaceAll(".", "-")
              )
          : undefined,
      minBudget,
      maxBudget,
      rooms,
    });
  };

  const convertQueryParamsToTags = (
    {
      tags,
      activities,
      activityTypes,
      locations,
      locationTypes,
      propertyTypes,
    }: IBodyParamsArgs,
    setter: (activeTags: IFilteringEntryResponse[]) => void
  ): void => {
    const activeTags = [];

    if (tags && typeof tags === "string") {
      activeTags.push(
        ...tags.split(" ").map((tag) => {
          return {
            title: tag
              .replaceAll("_", ", ")
              .replaceAll("-", " ")
              .replaceAll(".", "-"),
            type: TAG_TYPES.TAG,
          };
        })
      );
    }
    if (activities && typeof activities === "string") {
      activeTags.push(
        ...activities.split(" ").map((tag) => {
          return {
            title: tag
              .replaceAll("_", ", ")
              .replaceAll("-", " ")
              .replaceAll(".", "-"),
            type: TAG_TYPES.ACTIVITY,
          };
        })
      );
    }
    if (activityTypes && typeof activityTypes === "string") {
      activeTags.push(
        ...activityTypes.split(" ").map((tag) => {
          return {
            title: tag
              .replaceAll("_", ", ")
              .replaceAll("-", " ")
              .replaceAll(".", "-"),
            type: TAG_TYPES.ACTIVITY_TYPE,
          };
        })
      );
    }
    if (locations && typeof locations === "string") {
      activeTags.push(
        ...locations.split(" ").map((tag) => {
          return {
            title: tag
              .replaceAll("_", ", ")
              .replaceAll("-", " ")
              .replaceAll(".", "-"),
            type: TAG_TYPES.LOCATION,
          };
        })
      );
    }
    if (locationTypes && typeof locationTypes === "string") {
      activeTags.push(
        ...locationTypes.split(" ").map((tag) => {
          return {
            title: tag
              .replaceAll("_", ", ")
              .replaceAll("-", " ")
              .replaceAll(".", "-"),
            type: TAG_TYPES.LOCATION_TYPE,
          };
        })
      );
    }
    if (propertyTypes && typeof propertyTypes === "string") {
      activeTags.push(
        ...propertyTypes.split(" ").map((tag) => {
          return {
            title: tag
              .replaceAll("_", ", ")
              .replaceAll("-", " ")
              .replaceAll(".", "-"),
            type: TAG_TYPES.PROPERTY_TYPE,
          };
        })
      );
    }

    setter(activeTags);
  };

  const generateAlgoliaFilters = useCallback(
    (
      bodyParamsForFetch: string,
      dates: {
        startDate: string | undefined;
        endDate: string | undefined;
      },
      nights: {
        oneDestination: INightsComponentResponse;
        twoDestination: INightsComponentResponse;
        threeDestination: INightsComponentResponse;
      }
    ) => {
      const selectedNumberOfNights = dayjs(
        dates.endDate?.replaceAll(" ", "+")
      ).diff(dayjs(dates.startDate?.replaceAll(" ", "+")), "days", false);

      const filterOneDestination =
        selectedNumberOfNights >= Number(nights?.oneDestination?.minNights) &&
        selectedNumberOfNights <= Number(nights?.oneDestination?.maxNights);

      const filterTwoDestination =
        selectedNumberOfNights >= Number(nights?.twoDestination?.minNights) &&
        selectedNumberOfNights <= Number(nights?.twoDestination?.maxNights);

      const filterThreeDestination =
        selectedNumberOfNights >= Number(nights?.threeDestination?.minNights) &&
        selectedNumberOfNights <= Number(nights?.threeDestination?.maxNights);

      let nightsFilter = "";

      if (filterOneDestination && filterTwoDestination) {
        nightsFilter = "locationsLength != 3";
      } else if (filterOneDestination && filterThreeDestination) {
        nightsFilter = "locationsLength != 2";
      } else if (filterTwoDestination && filterThreeDestination) {
        nightsFilter = "locationsLength != 2.5";
      } else if (filterOneDestination) {
        nightsFilter = "locationsLength = 2.5";
      } else if (filterTwoDestination) {
        nightsFilter = "locationsLength = 2";
      } else if (filterThreeDestination) {
        nightsFilter = "locationsLength = 3";
      } else {
        // Handling case where no results should be returned as per current AC
        nightsFilter =
          dates?.startDate && dates?.endDate ? "locationsLength > 3" : "";
      }

      let filters = "";

      const parsedParams = JSON.parse(bodyParamsForFetch);

      if (Object.keys(parsedParams).length > 0) {
        filters = Object.keys(parsedParams)
          .map((filter) => {
            switch (filter) {
              case "tags":
                return parsedParams?.tags?.length > 0
                  ? `(tags.name:"${parsedParams.tags[0]}" OR secondary_tags.name:"${parsedParams.tags[0]}")`
                  : "";
              case "activityTypes":
                return parsedParams?.activityTypes?.length > 0
                  ? `(${parsedParams?.activityTypes
                      .map(
                        (propertyType: string) =>
                          `activities.activity_types.Title:"${propertyType}"`
                      )
                      .join(
                        `${
                          filtersContext?.isSearchShowingClosestResults
                            ? " OR "
                            : " AND "
                        }`
                      )})`
                  : "";
              case "locationTypes":
                return parsedParams?.locationTypes?.length > 0
                  ? `(${parsedParams?.locationTypes
                      .map(
                        (locationType: string) =>
                          `location_types.Title:"${locationType}"`
                      )
                      .join(
                        `${
                          filtersContext?.isSearchShowingClosestResults
                            ? " OR "
                            : " AND "
                        }`
                      )})`
                  : "";
              case "propertyTypes":
                return parsedParams?.propertyTypes?.length > 0
                  ? `(${parsedParams?.propertyTypes
                      .map(
                        (propertyType: string) =>
                          `accommodations.property_types.Title:"${propertyType}"`
                      )
                      .join(" OR ")})`
                  : "";
              case "minBudget":
                return `${(() => {
                  const minBudget = Number(parsedParams.minBudget);
                  const maxBudget = Number(parsedParams.maxBudget);

                  if (maxBudget === BUDGETS.MAX) {
                    if (minBudget !== BUDGETS.MIN) {
                      return `accommodations.Rate > ${minBudget}`;
                    }
                    return "";
                  } else {
                    if (minBudget !== BUDGETS.MIN) {
                      return `accommodations.Rate:${minBudget} TO ${maxBudget}`;
                    }
                    return `accommodations.Rate < ${maxBudget}`;
                  }
                })()}`;
              case "maxBudget":
                // Return empty string as the only case we need to handle is minBudget because the budget query params go in a pair
                return "";
              default:
                return "";
            }
          })
          .filter((filterValue) => filterValue !== "")
          .join(" AND ");
      }

      if (filters.length > 0) {
        filters = filters.concat(
          nightsFilter.length > 0 ? ` AND ${nightsFilter}` : ""
        );
      } else {
        if (nightsFilter.length > 0) {
          filters = nightsFilter;
        }
      }

      return filters;
    },
    [filtersContext?.isSearchShowingClosestResults]
  );

  return {
    buildQueryParameters,
    getBodyParams,
    convertQueryParamsToTags,
    generateAlgoliaFilters,
  };
};

export default useSearch;
