import { onEscapeDown } from "@components/utils/accessibilityHelpers";
import { useEffect } from "react";

interface IBackDropProps {
  closeModalHandler?: () => void;
  isDark?: boolean;
}

const BackDrop = ({
  closeModalHandler,
  isDark,
}: IBackDropProps): JSX.Element => {
  useEffect(() => {
    const handleUserKeyPress = (e: Event): void => {
      if (closeModalHandler) {
        onEscapeDown(e, closeModalHandler);
      }
    };
    window.addEventListener("keydown", (e) => handleUserKeyPress(e));
    return () => {
      window.removeEventListener("keydown", (e) => handleUserKeyPress(e));
    };
  });

  return (
    <div
      id="backdrop"
      className={
        isDark
          ? "fixed top-0 left-0 z-[100] h-full w-full bg-black bg-opacity-50"
          : "fixed top-0 left-0 z-[50] h-full w-full"
      }
      onClick={closeModalHandler}
    />
  );
};

export default BackDrop;
