import ConditionalWrapper from "@components/common/ConditionalWrapper";
import FindTripButton from "@components/common/FindTripButton";
import { shimmer, toBase64 } from "@components/skeleton/ImageCloudinary";
import { ImageDefault } from "@components/utils/Utils";
import { checkHrefDomain, checkPhoneLink } from "@libs/parser/utils/helpers";
import parse, {
  DOMNode,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";
import { Attributes } from "html-react-parser/lib/attributes-to-props";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import Image from "next/image";

const IFrameLazyLoadContainer = dynamic(
  async () => await import("./components/IFrameLazyLoadContainer"),
  { ssr: false }
);

interface ReactParserOptions extends Omit<HTMLReactParserOptions, "replace"> {
  replace: ({
    children,
    attribs,
    name,
    parent,
  }: {
    children: DOMNode[];
    attribs: Attributes;
    name: string;
    parent: any;
  }) => JSX.Element | undefined;
}

interface SanitizeProps {
  style?: {
    color?: string;
    fontFamily?: string;
    fontSize?: string;
    lineHeight?: string;
  };
  className?: string;
}

const sanitizeStyles = (
  props: SanitizeProps,
  children?: DOMNode[],
  parent?: any,
  name?: string
): void => {
  delete props?.style?.color;
  delete props?.style?.fontFamily;
  delete props?.style?.fontSize;
  if (!props.style) {
    props.style = {};
  }
  if (parent?.name === "a" || (parent?.name?.includes("h") && name !== "a")) {
    props.className = "";
  }
  props.style.lineHeight =
    // @ts-expect-error
    children.length === 1 && children[0].data?.trim() === "" ? "0px" : "";
};

interface ISPSettings {
  skipParagraphStyling?: boolean;
  isHeroEyebrow?: boolean;
}

const styledParser = (
  content: string | undefined,
  settings?: ISPSettings
): ReturnType<typeof domToReact> => {
  const options: ReactParserOptions = {
    replace: ({
      children,
      attribs,
      name,
      parent,
    }: {
      children: DOMNode[];
      attribs: Attributes;
      name: string;
      parent: any;
    }): JSX.Element | undefined => {
      const props = attributesToProps(attribs);

      switch (name) {
        case "p":
          if (!attribs) {
            return;
          }
          if (attribs) {
            props.className = settings?.skipParagraphStyling
              ? ""
              : `${
                  children?.every(
                    // @ts-expect-error
                    (child) => child.data?.trim() === "" || child.name === "br"
                  )
                    ? ""
                    : "mb-6"
                } text-darkgray desktop:text-p text-p14 font-sans`;
            sanitizeStyles(props as SanitizeProps, children, parent, name);
            props.style = {
              ...props.style,
            };
            return (
              <div {...props}>
                {domToReact(children, options as HTMLReactParserOptions)}
              </div>
            );
          }
          break;
        case "li":
          if (!attribs) {
            return;
          }
          if (attribs) {
            props.className = `${
              children?.every(
                // @ts-expect-error
                (child) => child.data?.trim() === "" || child.name === "br"
              )
                ? ""
                : "mb-4"
            } text-darkgray desktop:text-p text-p14 font-sans`;
            sanitizeStyles(props as SanitizeProps, children, parent, name);
            props.style = {
              ...props.style,
            };
            return (
              <li {...props}>
                {domToReact(children, options as HTMLReactParserOptions)}
              </li>
            );
          }
          break;
        case "h2":
          if (!attribs) {
            return;
          }
          props.className = "desktop:mb-3 mb-2 text-h2 text- font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = {
            ...props.style,
          };
          return (
            <h2 {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </h2>
          );
        case "h3":
          if (!attribs) {
            return;
          }
          props.className = "desktop:mb-3 mb-2 text-h3 font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = {
            ...props.style,
          };
          return (
            <h3 {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </h3>
          );
        case "h4":
          if (!attribs) {
            return;
          }
          props.className = "desktop:mb-3 mb-2 text-h4 font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = {
            ...props.style,
          };
          return (
            <h4 {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </h4>
          );
        case "h5":
          if (!attribs) {
            return;
          }
          props.className = "desktop:mb-3 mb-2 text-h5 font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = {
            ...props.style,
          };
          return (
            <h5 {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </h5>
          );
        case "h6":
          if (!attribs) {
            return;
          }
          props.className = "desktop:mb-3 mb-2 text-h6 font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = {
            ...props.style,
          };
          return (
            <h6 {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </h6>
          );
        case "a":
          if (!attribs) {
            return;
          }
          props.className =
            "text-lightblue hover:text-darkblue underline font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          const isPhoneLink = checkPhoneLink(attribs.href);
          const isInnerLink = checkHrefDomain(attribs.href);
          const aTagTarget = isPhoneLink || isInnerLink ? undefined : "_blank";

          /** NAM-2449 */
          if (
            props.href === undefined &&
            (props.id !== "" || typeof props.id !== "undefined")
          ) {
            props.style = {
              opacity: "0",
              position: "relative",
              top: "-80px",
            };
            props.rel = "noopener";
          }
          const isFindTripLink = props.href?.includes("find-trip");
          const findTripButtonCSS = settings?.isHeroEyebrow
            ? "text-p w-auto cursor-pointer font-sans font-semibold text-orange underline hover:text-orange-100 z-[1]"
            : "text-lightblue hover:text-darkblue underline font-sans inline cursor-pointer";

          return isFindTripLink ? (
            <ConditionalWrapper
              condition={settings?.isHeroEyebrow}
              wrapper={(children) => {
                return <div className="flex w-full"> {children}</div>;
              }}
            >
              <FindTripButton className="find-trip-button">
                <div className="flex items-center justify-center gap-2 hover:cursor-pointer">
                  <Image
                    src="/searchicon.svg"
                    height={24}
                    width={24}
                    alt="search"
                  />
                  {domToReact(children, options as HTMLReactParserOptions)}
                </div>
              </FindTripButton>
            </ConditionalWrapper>
          ) : (
            <a {...props} target={aTagTarget}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </a>
          );
        case "strong":
          if (!attribs) {
            return;
          }
          props.className = "font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <strong {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </strong>
          );
        case "i":
          if (!attribs) {
            return;
          }
          props.className = "font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <i {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </i>
          );
        case "ul":
          if (!attribs) {
            return;
          }
          props.className = "ml-8 mb-4 font-sans";
          if (!props.style) {
            props.style = {};
            props.style.listStyleType = "disc";
          }
          if (props.style && !props.style.listStyleType) {
            props.style.listStyleType = "disc";
          }
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <ul {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </ul>
          );
        case "ol":
          if (!attribs) {
            return;
          }
          props.className = "ml-8 mb-4 font-sans";
          if (!props.style) {
            props.style = {};
            props.style.listStyleType = "decimal";
          }
          if (props.style && !props.style.listStyleType) {
            props.style.listStyleType = "decimal";
          }
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <ol {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </ol>
          );
        case "blockquote":
          if (!attribs) {
            return;
          }
          props.className = "italic font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <blockquote {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </blockquote>
          );
        case "span":
          if (!attribs) {
            return;
          }
          props.className = `${
            // @ts-expect-error
            children.length === 1 && children[0].data?.trim() === ""
              ? ""
              : "mb-4"
          } text-darkgray desktop:text-p text-p14 font-sans`;
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <span {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </span>
          );
        case "figure":
          if (!attribs) {
            return;
          }
          props.className = "mb-4";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <figure {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </figure>
          );

        case "iframe":
          return (
            <>
              <IFrameLazyLoadContainer props={props}>
                {domToReact(children, options as HTMLReactParserOptions)}
              </IFrameLazyLoadContainer>
            </>
          );

        case "img":
          if (!attribs) {
            return;
          }

          const isCloudinaryImage = props.src?.includes("cloudinary");

          if (isCloudinaryImage) {
            const width = 960;
            const height = 480;
            const { publicRuntimeConfig } = getConfig();
            const imgArr = props.src.split("/");
            const imgName = imgArr[7];
            const imgSrc = `https://res.cloudinary.com/${publicRuntimeConfig.CLOUD_NAME}/image/upload/c_scale,w_640,q_auto,f_webp/${imgName}`;

            return (
              <Image
                {...props}
                src={ImageDefault(imgSrc)}
                width={width}
                height={height}
                alt={props.alt ?? ""}
                //priority={true}
                loading={undefined}
                placeholder="blur"
                blurDataURL={`data:image/svg+xml;base64,${toBase64(
                  shimmer(width, height)
                )}`}
                sizes="(max-width: 576px) 50vw, 100vw"
              />
            );
          }
          return <img {...props} loading="lazy" />;
        case "section":
          props.style = {
            ...props.style,
            paddingTop: "60px",
          };
          return (
            <section {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </section>
          );
      }
    },
  };
  if (content) {
    return parse(content, options as HTMLReactParserOptions);
  }
  return "";
};

export default styledParser;
