import useDetectDevice from "@hooks/useDetectDevice";
import React, { createContext, useState } from "react";

export interface IAppUtilsContext {
  state: {
    loading: boolean;
  };
  sliderRefresh: boolean;
  setSliderRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showLoginModal: boolean;
  setShowLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
  showHeaderMenuMobile: boolean;
  setShowHeaderMenuMobile: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingAuthAction: {
    loggingIn: boolean;
    loggingOut: boolean;
  };
  setIsLoadingAuthAction: React.Dispatch<
    React.SetStateAction<{
      loggingIn: boolean;
      loggingOut: boolean;
    }>
  >;
  raqToast: boolean;
  setRaqToast: React.Dispatch<React.SetStateAction<boolean>>;
  showToastMessage: boolean;
  setShowToastMessage: React.Dispatch<React.SetStateAction<boolean>>;
  toastMessage: string;
  setToastMessage: React.Dispatch<React.SetStateAction<string>>;
  isMobile: boolean;
  isRealMobileDevice: boolean;
  isDesktop: boolean;
  isTablet: boolean;
}

interface IAppUtilsProvider {
  children: JSX.Element | JSX.Element[];
}

const AppUtilsContext = createContext<IAppUtilsContext | null>(null);

const AppUtilsProvider = ({ children }: IAppUtilsProvider): JSX.Element => {
  const { isMobile, isRealMobileDevice, isDesktop, isTablet } =
    useDetectDevice();

  const [loading, setLoading] = useState(true);
  const [sliderRefresh, setSliderRefresh] = useState(false);
  const [isLoadingAuthAction, setIsLoadingAuthAction] = useState({
    loggingIn: false,
    loggingOut: false,
  });
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showHeaderMenuMobile, setShowHeaderMenuMobile] = useState(false);
  const [raqToast, setRaqToast] = useState(false);

  return (
    <AppUtilsContext.Provider
      value={{
        state: {
          loading,
        },
        sliderRefresh,
        setSliderRefresh,
        setLoading,
        setShowLoginModal,
        showLoginModal,
        showHeaderMenuMobile,
        setShowHeaderMenuMobile,
        isLoadingAuthAction,
        setIsLoadingAuthAction,
        raqToast,
        setRaqToast,
        showToastMessage,
        toastMessage,
        setShowToastMessage,
        setToastMessage,
        isMobile,
        isRealMobileDevice,
        isDesktop,
        isTablet,
      }}
    >
      {children}
    </AppUtilsContext.Provider>
  );
};

export { AppUtilsContext, AppUtilsProvider };
