import dayjs, { Dayjs } from "dayjs";

function isInclusivelyAfterDay(a: Dayjs | null, b: Dayjs | null): boolean {
  if (!dayjs.isDayjs(a) || !dayjs.isDayjs(b)) return false;
  return !isBeforeDay(a, b);
}
function isBeforeDay(a: Dayjs | null, b: Dayjs | null): boolean {
  if (!dayjs.isDayjs(a) || !dayjs.isDayjs(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}
const dateRangePickerConfigNamu = {
  // example props for the demo
  // startDateOffset: undefined,
  // endDateOffset: undefined,
  // minDate: null,
  // maxDate: null,
  // hideKeyboardShortcutsPanel: true,

  // day presentation and interaction related props
  // renderCalendarDay: undefined,
  // renderDayContents: null,
  // minimumNights: 1,
  // enableOutsideDays: false,
  // daysViolatingMinNightsCanBeClicked: false,

  // calendar presentation and interaction related props
  // verticalHeight: undefined,
  inline: true,
  withPortal: true,
  selectsRange: true,

  // keepOpenOnDateSelect: false,
  // internationalization
  // monthFormat: "MMMM YYYY",
};

export default dateRangePickerConfigNamu;
