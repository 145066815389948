import React from "react";

interface IMainWrapper {}

const MainWrapper = ({
  children,
}: React.PropsWithChildren<IMainWrapper>): JSX.Element => {
  // Hardcoded pixels are the height of the navigation bar;
  return (
    <main
      className={`min-h-[100vh] overflow-hidden pt-[4.5rem] desktop:overflow-visible desktop:pt-0`}
    >
      {children}
    </main>
  );
};

export default MainWrapper;
