import { onEnterDown } from "@components/utils/accessibilityHelpers";
import { IPropertyCategory } from "@customTypes/filtersProvider";
import dynamic from "next/dynamic";
import { FiltersContext } from "providers/FiltersProvider";
import { useContext, useMemo } from "react";
const HotelIcon = dynamic(
  async () => await import("@components/icons/HotelIcon")
);
const RentalIcon = dynamic(
  async () => await import("@components/icons/RentalIcon")
);

interface IAccommodationTypeButton {
  variant: "hotel" | "rental";
  item?: IPropertyCategory<"Hotel"> | IPropertyCategory<"Rental">;
}

const AccommodationTypeButton = ({
  variant,
  item,
}: IAccommodationTypeButton): JSX.Element => {
  const filtersContext = useContext(FiltersContext);

  const content = useMemo(() => {
    switch (variant) {
      case "hotel":
        return {
          text: "Hotel",
          icon: <HotelIcon />,
        };
      case "rental":
        return {
          text: "Rental",
          icon: <RentalIcon />,
        };
    }
  }, [variant]);

  const onClickHandler = (): void => {
    if (item) {
      filtersContext?.changeAccommodationCategory(item);
    }
  };

  return (
    <div
      className={`py-auto flex h-[80px] w-full cursor-pointer flex-col items-center gap-1 rounded-xl bg-white pt-4 ${
        item?.isChecked ? "ring-1 ring-orange" : ""
      }`}
      onClick={onClickHandler}
      tabIndex={0}
      onKeyDown={(e) => onEnterDown(e, onClickHandler)}
    >
      {content.icon}
      <p className="text-p text-darkblue">{content.text}</p>
    </div>
  );
};

export default AccommodationTypeButton;
