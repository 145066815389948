import getConfig from "next/config";
import Image from "next/image";
import React from "react";

declare type SafeNumber = number | `${number}`;

interface IImageCloudinaryProps {
  src: string;
  imageName?: string;
  width?: SafeNumber;
  height?: SafeNumber;
  alt?: string;
  className?: string;
  layout?: "fill" | "fixed" | "intrinsic" | "responsive";
  objectFit?: any;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
  priority?: boolean;
  sizes?: string;
  quality?: SafeNumber;
  isHero?: boolean;
}

export const shimmer = (
  w: number | string | undefined,
  h: number | string | undefined
): string => `
  <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="g">
        <stop stop-color="#E8E8E8" offset="20%" />
        <stop stop-color="#E9E9E9" offset="50%" />
        <stop stop-color="#E8E8E8" offset="70%" />
      </linearGradient>
    </defs>
    <rect width="${w}" height="${h}" fill="#E8E8E8" />
    <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
    <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
  </svg>`;

export const toBase64 = (str: string): string =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

function ImageCloudinary({
  src,
  width,
  alt,
  height,
  className,
  layout,
  objectFit,
  onClick,
  priority = false,
  sizes = "100vw",
  quality = 75,
  isHero = false,
}: IImageCloudinaryProps): JSX.Element {
  const { publicRuntimeConfig } = getConfig();

  const customLoader = ({ src, width: w, quality: q }: any) => {
    const img = `https://res.cloudinary.com/${
      publicRuntimeConfig.CLOUD_NAME
    }/image/upload/c_scale,w_${w},q_${q || 75},f_webp${src}`;
    return img;
  };

  const imgArr = src.split("/");
  const imgName = imgArr[7];
  const imgHeroName = `/${imgArr[6]}/${imgArr[7]}`;

  const imgWidth = width as number;
  const imgSrc = `https://res.cloudinary.com/${
    publicRuntimeConfig.CLOUD_NAME
  }/image/upload/c_scale,w_${imgWidth},q_${quality || 75},f_webp/${imgName}`;

  const props: any = {
    src: imgSrc,
  };

  if (isHero) {
    props.src = imgHeroName;
    props.loader = customLoader;
    props.fill = true;
    props.style = { objectFit: "cover" };
    props.sizes = sizes;
  } else {
    props.src = imgSrc;
    props.width = width;
    props.height = height;
    props.style = { width: "100%", objectFit: "cover" };
  }

  return (
    <Image
      {...props}
      alt={alt}
      className={className}
      onClick={onClick}
      priority={priority}
      quality={quality}
      placeholder="blur"
      blurDataURL={`data:image/svg+xml;base64,${toBase64(
        shimmer(width, height)
      )}`}
    />
  );
}

export default ImageCloudinary;
