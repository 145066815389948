export const MAX_LENGTH = {
  name: 64,
  email: 320,
  phone: 15,
  phoneWithText: 64,
  description: 3000,
};

export const ERROR_TEXTS = {
  requiredFullName: "Full Name field is required",
  maxFullName: `Username must not exceed ${MAX_LENGTH.name} characters`,
  requiredEmail: "Email field is required",
  validEmail: "Please enter valid email address",
  requiredDates: "Dates field is required",
};

export const INITIAL_FORM_VALUE = {
  adults: 2,
  teens: 0,
  children: 0,
  rooms: 1,
  budgets: { min: 250, max: 500 },
};
