import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface HeaderContextProps {
  headers: Record<string, string> | null;
  setHeaders: (headers: Record<string, string>) => void;
}

const HeaderContext = createContext<HeaderContextProps | undefined>(undefined);

export const useHeaders = (): HeaderContextProps => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error("useHeaders must be used within a HeaderProvider");
  }
  return context;
};

interface HeaderProviderProps {
  children: ReactNode;
}

export const HeaderProvider = ({
  children,
}: HeaderProviderProps): JSX.Element => {
  const [headers, setHeaders] = useState<Record<string, string> | null>(null);

  useEffect(() => {
    (async () => {
      const res = await fetch("/api/headers");
      const data = await res.json();
      setHeaders(data.headers);
    })();
  }, []);

  return (
    <HeaderContext.Provider value={{ headers, setHeaders }}>
      {children}
    </HeaderContext.Provider>
  );
};
