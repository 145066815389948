import React from "react";

interface Map {
  [key: string]: string | undefined;
}

const BUTTON_SIZE: Map = {
  large: "h-[58px]",
  medium: "h-[48px]",
  small: "h-[40px]",
  extrasmall: "h-[36px]",
  default: "h-[58px]",
};

const COLOR_SCHEME: Map = {
  primary: "primary-button-color-scheme",
  secondary: "secondary-button-color-scheme",
  "secondary-light": "secondary-light-button-color-scheme",
  tertiary: "tertiary-button-color-scheme",
  "no-outline": "text-orange",
  default: "primary-button-color-scheme",
};

interface IButtonProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  variant?: string;
  text?: string;
  counter?: any;
  id?: string;
  type?: "submit" | "button" | "reset" | undefined;
  children?: JSX.Element | JSX.Element[] | string;
  size?: string;
  disabled?: boolean;
}

const Button = ({
  onClick,
  variant,
  text,
  counter,
  id,
  type,
  children,
  size,
  disabled,
}: IButtonProps): JSX.Element => {
  return (
    <div className="w-full">
      <button
        id={id}
        type={type}
        className={`px-4 ${
          variant ? COLOR_SCHEME[variant] : COLOR_SCHEME.default
        } flex w-full items-center justify-center rounded-full font-normal ${
          size ? BUTTON_SIZE[size] : BUTTON_SIZE.default
        }`}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
        {children}
        {counter && (
          <div className="ml-4 rounded-md bg-blue-5 p-[0.375rem] font-semibold text-orange">
            {counter}
          </div>
        )}
      </button>
    </div>
  );
};

export default Button;
