import { useRouter } from "next/router";
import { useEffect } from "react";
import { IMetaDataPagePropsResponse } from "../types/commonResponses";
import { PAGE_TYPES } from "../types/index";

const useAnalytics = (pageProps: IMetaDataPagePropsResponse): void => {
  const router = useRouter();
  let title = "not specified";

  switch (Number(pageProps.pageType)) {
    case PAGE_TYPES.Home:
      title = "Home";
      break;
    case PAGE_TYPES.Packages:
      title = pageProps?.packages?.Long_Title ?? "";
      break;
    case PAGE_TYPES.Location:
      title = pageProps?.packages?.Long_Title ?? "";
      break;
    case PAGE_TYPES.LandingPage:
      title = pageProps?.landingPage?.Title ?? "";
      break;
    case PAGE_TYPES.Accommodations:
      title = pageProps?.accommodations?.Long_Title ?? "";
      break;
    case PAGE_TYPES.Activity:
      title = pageProps?.activity?.Long_Title ?? "";
      break;
    case PAGE_TYPES.Page:
      title = pageProps?.page?.Title ?? "";
      break;
    case PAGE_TYPES.Article:
      title = pageProps?.article?.Title ?? "";
      break;
    case PAGE_TYPES.TravelConsultant:
      title = pageProps?.travelConsultant?.Name ?? "";
      break;
    case PAGE_TYPES.Favorites:
      title = "Favorites";
      break;
    case PAGE_TYPES.ContactPage:
      title = "Contact Us";
      break;
    case PAGE_TYPES.PackageSearch:
      title = "Package Search";
      break;
  }

  useEffect(() => {
    if (
      !router.asPath.includes("package-search") ||
      (router.asPath.includes("package-search") &&
        router.asPath.includes("page="))
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "PageView",
        pagePath: router.asPath,
        pageTitle: title,
      });
    }
  }, [router.asPath, title]);
};

export default useAnalytics;
