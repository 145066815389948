import { useMemo } from "react";
import useScreenType from "./useScreenType";

interface IUseDetectDeviceReturn {
  isMobile: boolean;
  isRealMobileDevice: boolean;
  isDesktop: boolean;
  isTablet: boolean;
}

const useDetectDevice = (): IUseDetectDeviceReturn => {
  const screenType = useScreenType();

  const isDesktop = useMemo(() => {
    return screenType.variant === "desktop";
  }, [screenType]);

  const isTablet = useMemo(() => {
    return screenType.type === "tablet";
  }, [screenType]);

  const isMobile = useMemo(() => {
    return screenType.type === "mobile";
  }, [screenType]);

  const isRealMobileDevice = useMemo(() => {
    return screenType.variant === "real";
  }, [screenType]);

  return {
    isMobile,
    isRealMobileDevice,
    isDesktop,
    isTablet,
  };
};

export default useDetectDevice;
