import { FILTER } from "@components/utils/constants";
import { FiltersContext } from "providers/FiltersProvider";
import { useContext, useMemo } from "react";
import AccommodationMoreOptions from "./AccommodationMoreOptions";

const AccommodationPropertyList = (): JSX.Element => {
  const filtersContext = useContext(FiltersContext);

  const filterHotelItems = useMemo(() => {
    if (filtersContext?.searchManagement) {
      return filtersContext?.searchManagement[
        FILTER.ACCOMMODATIONS.filterType
      ].hotels.categories.map((item) => {
        item[FILTER.ACCOMMODATIONS.strapiName].isChecked =
          filtersContext.searchQuery[
            FILTER.ACCOMMODATIONS.name
          ].hotels.categories
            .map((searchItem) => {
              return searchItem.id;
            })

            .includes(item[FILTER.ACCOMMODATIONS.strapiName].id);
        return item;
      });
    }
    return [];
  }, [filtersContext]);

  const filterRentalItems = useMemo(() => {
    if (filtersContext?.searchManagement) {
      return filtersContext.searchManagement[
        FILTER.ACCOMMODATIONS.filterType
      ].rentals.categories.map((item) => {
        item[FILTER.ACCOMMODATIONS.strapiName].isChecked =
          filtersContext.searchQuery[
            FILTER.ACCOMMODATIONS.name
          ].rentals.categories
            .map((searchItem) => {
              return searchItem.id;
            })
            .includes(item[FILTER.ACCOMMODATIONS.strapiName].id);
        return item;
      });
    }
    return [];
  }, [filtersContext]);

  return (
    <AccommodationMoreOptions
      hotels={filterHotelItems}
      rentals={filterRentalItems}
    />
  );
};

export default AccommodationPropertyList;
