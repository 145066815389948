// @ts-nocheck
// will be removed when file is properly refactored to tsx.
import { BUDGETS, LOCAL_STORAGE } from "@components/utils/constants";
import { sanitizeBudgetNumber } from "@components/utils/sanitizeHelpers";
import dynamic from "next/dynamic";
import { FiltersContext } from "providers/FiltersProvider";
import "rc-slider/assets/index.css";
import { useContext, useEffect } from "react";
const MIN = 0;
const MAX = 1;
const TooltipSlider = dynamic(async () => await import("rc-slider"));

interface IMultiRangePriceProps {
  isSearch?: boolean;
  setRaQValue?: (budgets: { min: number; max: number }) => void;
}

const MultiRangePrice = ({
  isSearch,
  setRaQValue,
}: IMultiRangePriceProps): JSX.Element => {
  const filtersContext = useContext(FiltersContext);

  const budget = filtersContext.searchQuery.accommodations.budget;

  const onChange = (ranges): void => {
    filtersContext.updateBudget(ranges[MIN], ranges[MAX]);
    if (!isSearch) {
      const userSearchFilters = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE.userSearchFilters)
      );

      localStorage.setItem(
        LOCAL_STORAGE.userSearchFilters,
        userSearchFilters
          ? JSON.stringify({
              ...userSearchFilters,
              accommodations: {
                ...userSearchFilters.accommodations,
                budget: {
                  min: ranges[MIN],
                  max: ranges[MAX],
                  isTouched: true,
                },
              },
            })
          : JSON.stringify({
              accommodations: {
                budget: {
                  min: ranges[MIN],
                  max: ranges[MAX],
                  isTouched: true,
                },
              },
            })
      );
    }
  };

  // Selects the budget slider knobs
  const sliderSelector = document.querySelector(".rc-slider-handle");
  useEffect(() => {
    const preventDefaultBack = (event): void => {
      event.preventDefault();
    };
    // Prevents iPhone activiting the safari browser swipe right on edge of screen to back;
    if (sliderSelector != null) {
      sliderSelector.addEventListener("touchstart", preventDefaultBack);
    }
    return () => {
      if (sliderSelector != null) {
        sliderSelector.removeEventListener("touchstart", preventDefaultBack);
      }
    };
  }, [sliderSelector]);

  useEffect(() => {
    let CURRENT_STATE = localStorage.getItem("USER_REQUEST_QUOTE");
    if (CURRENT_STATE !== null) {
      CURRENT_STATE = JSON.parse(CURRENT_STATE);
      const budgets = {
        min: budget.min,
        max: budget.max,
      };
      CURRENT_STATE.budgets = budgets;
      localStorage.setItem("USER_REQUEST_QUOTE", JSON.stringify(CURRENT_STATE));
    }
    if (setRaQValue) {
      setRaQValue({
        min: budget.min,
        max: budget.max,
      });
    }
  }, [budget.min, budget.max]);

  return (
    <div className="relative">
      <div
        className={`items-center justify-center
                      rounded-lg ${
                        isSearch
                          ? "h-fit"
                          : setRaQValue
                          ? "h-fit bg-white"
                          : "h-60 bg-white"
                      }`}
      >
        <div
          className={`flex w-full flex-row justify-between ${
            isSearch ? "gap-2" : "gap-4"
          } bg-blue-5 pb-6`}
        >
          <div className={`flex flex-col ${setRaQValue ? "relative" : ""}`}>
            {!isSearch && (
              <div
                className={`pl-6 text-p13 font-medium ${
                  setRaQValue
                    ? "absolute mt-3 h-full text-p13 text-darkgray"
                    : "mb-[0.375rem]"
                }`}
              >
                <span>Min</span>
              </div>
            )}
            <div
              className={`flex items-center ${
                isSearch && "rounded-full bg-white pl-6"
              }`}
            >
              {isSearch && <div className="text-p13 text-midgray">Min</div>}
              <input
                aria-label="Minimum budget"
                className={`flex items-center rounded-full bg-white ${
                  setRaQValue
                    ? "h-[44px] max-w-[160px] pl-14 text-p"
                    : `h-[54px] ${
                        isSearch
                          ? "w-full pl-[0.625rem]"
                          : "max-w-[143px] pl-6 desktop:max-w-[163px]"
                      } text-h6`
                }`}
                readOnly
                value={sanitizeBudgetNumber(budget.min)}
                tabIndex={-1}
              />
            </div>
          </div>
          <div className={`flex flex-col ${setRaQValue ? "relative" : ""}`}>
            {!isSearch && (
              <div
                className={`pl-6 text-p13 font-medium ${
                  setRaQValue
                    ? "absolute mt-3 h-full text-p13 text-darkgray"
                    : "mb-[0.375rem]"
                }`}
              >
                Max
              </div>
            )}
            <div
              className={`flex items-center ${
                isSearch && "rounded-full bg-white pl-6"
              }`}
            >
              {isSearch && <div className="text-p13 text-midgray">Max</div>}
              <input
                aria-label="Maximum budget"
                className={`flex items-center rounded-full bg-white ${
                  setRaQValue
                    ? "h-[44px] max-w-[160px] pl-14 text-p"
                    : `h-[54px] ${
                        isSearch
                          ? "w-full pl-[0.625rem]"
                          : "max-w-[143px] pl-6 desktop:max-w-[163px]"
                      } text-h6`
                }`}
                readOnly
                value={sanitizeBudgetNumber(budget.max)}
                tabIndex={-1}
              />
            </div>
          </div>
        </div>
        <div className={`bg-blue-5 ${setRaQValue ? "px-4" : ""}`}>
          <TooltipSlider
            range
            min={BUDGETS.MIN}
            max={BUDGETS.MAX}
            step={BUDGETS.INCREMENT}
            value={[budget.min, budget.max]}
            tipFormatter={(value) => `${value}!`}
            onChange={onChange}
            trackStyle={{
              backgroundColor: "#10247E",
            }}
            dotStyle={{
              borderRadius: 0,
              borderColor: "#FFF",
            }}
            activeDotStyle={{
              borderRadius: 0,
              borderColor: "#FFF",
            }}
          />
        </div>
        {!setRaQValue && !isSearch && (
          // Used in RaQ type "A" (old one)
          <div className="mb-5 mt-[0.625rem] flex items-center justify-between pr-6">
            <div className="text-p13 text-darkgray">{`${sanitizeBudgetNumber(
              BUDGETS.MIN
            )}`}</div>
            <div className="text-p13 text-darkgray">{`${sanitizeBudgetNumber(
              BUDGETS.MAX
            )}`}</div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MultiRangePrice;
