import { BUDGETS, LOCAL_STORAGE } from "@components/utils/constants";
import { INITIAL_FORM_VALUE } from "@libs/forms/utils/constants";
import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { useEffect } from "react";

const usePreferencesSync: () => void = () => {
  useEffect(() => {
    if (!getCookie("_session")) {
      if (getCookie("_isLogIn")) {
        deleteCookie("_isLogIn");
      } else {
        const resetLikes = (): void => {
          localStorage.setItem("PAC_LIKE", JSON.stringify([]));
          localStorage.setItem("ACC_LIKES", JSON.stringify([]));
          localStorage.setItem("ACT_LIKES", JSON.stringify([]));
          localStorage.setItem("LOC_LIKES", JSON.stringify([]));
        };

        localStorage.setItem(
          LOCAL_STORAGE.userSearchFilters,
          JSON.stringify({
            experiences: [],
            locationTypes: [],
            accommodations: {
              budget: {
                min: BUDGETS.MIN,
                max: BUDGETS.MAX,
                isTouched: false,
              },
              hotels: { categories: [], isChecked: false, title: "Hotel" },
              rentals: { categories: [], isChecked: false, title: "Rental" },
            },
            activityTypes: [],
            travelers: { rooms: INITIAL_FORM_VALUE.rooms },
          })
        );
        localStorage.setItem(
          LOCAL_STORAGE.userSearchQueryParams,
          JSON.stringify({ startDate: null, endDate: null })
        );
        resetLikes();
        localStorage.removeItem("IS_PROMPTED_TO_LOGIN");
      }
    }

    if (getCookie("_isLogIn")) {
      deleteCookie("_isLogIn");
    }
  }, []);

  useEffect(() => {
    // Sets cookies initially so search doesn't reset on refreshes;
    const expiryDate = new Date();
    expiryDate.setSeconds(expiryDate.getSeconds() + 10);
    setCookie("_session", true, { expires: expiryDate });
    // ^^^^
    const cookieSchedule = setInterval(() => {
      const expiryDate = new Date();
      expiryDate.setSeconds(expiryDate.getSeconds() + 10);
      setCookie("_session", true, { expires: expiryDate });
    }, 9000);

    return () => clearInterval(cookieSchedule);
  }, []);
};

export default usePreferencesSync;
