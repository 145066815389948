import MediaAssets from "@components/utils/MediaAssets";
import dynamic from "next/dynamic";
import Image from "next/legacy/image";
const ExitButton = dynamic(
  async () => await import("@components/icons/ExitButton")
);
const BackButton = dynamic(
  async () => await import("@components/icons/BackButton")
);
interface IModalHeader {
  title: string;
  subtitle?: string;
  hasLogo?: boolean;
  onBackHandler?: () => void;
  onCloseHandler?: () => void;
  isFilterModal?: boolean;
}

const ModalHeader = ({
  title,
  subtitle,
  hasLogo,
  onBackHandler,
  onCloseHandler,
  isFilterModal,
}: IModalHeader): JSX.Element => {
  return (
    <div
      className={`relative flex shrink-0 flex-row items-center overflow-hidden desktop:mx-8 ${
        isFilterModal
          ? "mx-[0.875rem] my-[0.3125rem] desktop:desktop:my-8"
          : `mx-5 mt-4 bg-[#F3F4F8] ${!!subtitle ? "h-[65px]" : "h-[48px]"}`
      }`}
    >
      {hasLogo && (
        <div className="mr-auto h-fit w-fit">
          <Image
            src={MediaAssets.getAsset("frog_request_quote")}
            width="39"
            height="48"
            layout="fixed"
            alt="Request a quote"
          />
        </div>
      )}
      {onBackHandler && !hasLogo && (
        <div
          className={`mr-auto ${
            !isFilterModal
              ? `${!!subtitle ? "mb-[40px]" : "mb-[20px]"} -ml-[10px] `
              : ""
          }h-fit z-[1] w-fit`}
        >
          <BackButton onClick={onBackHandler} showBackground={false} />
        </div>
      )}
      {onCloseHandler && (
        <div
          className={`ml-auto mr-0 ${
            !isFilterModal
              ? `${!!subtitle ? "mb-[40px]" : "mb-[20px]"} -mr-[10px] `
              : ""
          }h-fit z-[1] w-fit`}
        >
          <ExitButton onClick={onCloseHandler} showBackground={false} />
        </div>
      )}
      <div className="absolute h-full w-full items-center">
        <div className="mx-auto flex w-fit flex-col items-center gap-2">
          <span
            className={`${
              isFilterModal
                ? "text-p font-medium desktop:text-h5"
                : "h-[20px] text-h3 font-bold"
            }`}
          >
            {title}
          </span>
          {subtitle && <p className="text-p text-[#364152]">{subtitle}</p>}
        </div>
      </div>
    </div>
  );
};

export default ModalHeader;
