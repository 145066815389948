import { FILTER } from "@components/utils/constants";
import { IQueryItemAccommodation } from "@customTypes/filtersProvider";
import InputCounter from "@libs/forms/InputCounter";
import { INITIAL_FORM_VALUE } from "@libs/forms/utils/constants";
import AccommodationFilterOption from "./AccommodationFilterOption";

interface IAccommodationMoreOptions {
  hotels: IQueryItemAccommodation[];
  rentals: IQueryItemAccommodation[];
}

const AccommodationMoreOptions = ({
  hotels,
  rentals,
}: IAccommodationMoreOptions): JSX.Element => {
  return (
    <div className="my-6 h-fit w-full">
      <div className="w-full">
        <p className="my-3 text-p14 text-darkgray">Preferences</p>
        {hotels.map((item) => (
          <AccommodationFilterOption
            key={item.id}
            item={item[FILTER.ACCOMMODATIONS.strapiName]}
          />
        ))}
      </div>
      <div className="w-full">
        {rentals.map((item) => (
          <AccommodationFilterOption
            key={item.id}
            item={item[FILTER.ACCOMMODATIONS.strapiName]}
          />
        ))}
      </div>
      <div className="pt-4">
        <InputCounter
          name="rooms"
          label="Number of Rooms"
          initialValue={INITIAL_FORM_VALUE.rooms}
        />
      </div>
    </div>
  );
};

export default AccommodationMoreOptions;
