import BurgerMenu from "@components/icons/BurgerMenu";
import { IMenuItemResponse } from "@customTypes/commonResponses";
import dynamic from "next/dynamic";
import HeaderMenuItem from "./HeaderMenuItem";

const CSSLoader = dynamic(async () => await import("@components/CSSLoader"), {
  ssr: false,
});

interface IHeaderMenuItemsProps {
  menuItems: IMenuItemResponse[];
}

const HeaderMenuItems = ({ menuItems }: IHeaderMenuItemsProps): JSX.Element => {
  return (
    <>
      <div className="desktop:hidden">
        <BurgerMenu />
      </div>
      <nav
        aria-label="Site Menu"
        className="hidden desktop:flex"
        id="navbar"
        role="navigation"
      >
        <CSSLoader header />
        <ul className="flex list-none" role="menu">
          {menuItems.map((menuItem, index) => {
            return menuItem.title !== undefined ? (
              <HeaderMenuItem item={menuItem} key={index} index={index} />
            ) : null;
          })}
        </ul>
      </nav>
    </>
  );
};

export default HeaderMenuItems;
