export const onEnterDown = (event: any, func: () => void): void => {
  if (event.key === "Enter") {
    func();
  }
};

export const onEscapeDown = (event: any, func: () => void): void => {
  if (event.key === "Escape") {
    func();
  }
};
