interface IGradientCover {
  rounded?: boolean;
  opacity?: number;
  bottomREM?: number;
}

interface Map {
  [key: number]: string | undefined;
}

const OPACITY: Map = {
  100: "opacity-100",
  80: "opacity-80",
};

const BOTTOM_REM: Map = {
  0: "bottom-0",
  3.5: "-bottom-[3.5rem]",
};

const GradientCover = ({
  rounded,
  opacity,
  bottomREM,
}: IGradientCover): JSX.Element => {
  return (
    <div
      className={`absolute h-1/4 w-full ${BOTTOM_REM[bottomREM ?? 0]} ${
        rounded ? "rounded-2xl" : ""
      } ${OPACITY[opacity ?? 100]} z-[1] bg-dark-cover`}
    />
  );
};

export default GradientCover;
