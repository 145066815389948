import { MEDIA_ASSETS } from "@components/home/routes";
import { PAGE_TYPES } from "@customTypes/index";
import fetchGet from "@services/api/fetchGet";
import { GetStaticProps } from "next";

const thankYouPaymentPage = (): JSX.Element => {
  return <div />;
};

export default thankYouPaymentPage;

export const getStaticProps: GetStaticProps = async () => {
  const mediaAssets = await fetchGet(MEDIA_ASSETS).then((res) => res.json());
  return {
    props: {
      pageType: PAGE_TYPES.ThankYouPaymentPage,
      mediaAssets,
    },
  };
};
