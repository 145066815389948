const CheckboxUncheckedIcon = (): JSX.Element => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6.71875"
      y="6.20898"
      width="20"
      height="20"
      rx="6"
      fill="#E7E9F2"
    />
  </svg>
);
export default CheckboxUncheckedIcon;
