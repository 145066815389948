import getConfig from "next/config";

export const checkHrefDomain = (href: string): boolean => {
  if (typeof href === "undefined" || href[0] === "#") {
    return true;
  }
  const { publicRuntimeConfig } = getConfig();
  const webUrl: string = publicRuntimeConfig.WEB_URL;
  const result = href.toLowerCase().includes(webUrl);
  return result;
};

export const checkPhoneLink = (href: string): boolean => {
  if (typeof href === "undefined" || href[0] === "#") {
    return true;
  }
  return href.includes("tel:");
};
