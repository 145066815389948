import ConditionalWrapper from "@components/common/ConditionalWrapper";
import Divider from "@components/common/Divider";
import Arrow from "@components/icons/Arrow";
import {
  onEnterDown,
  onEscapeDown,
} from "@components/utils/accessibilityHelpers";
import { RESERVED_URLS } from "@components/utils/constants";
import { slashBeforeUrlAdder } from "@components/utils/urlHelpers";
import { IMenuItemResponse } from "@customTypes/commonResponses";
import useAppContext from "@hooks/useAppContext";
import Link from "next/link";
import { useRouter } from "next/router";
import { FiltersContext } from "providers/FiltersProvider";
import {
  LegacyRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import HeaderSubmenu from "./HeaderSubmenu";

const DESKTOP_SIZE = 1248;

interface IHeaderMenuItemProps {
  item: IMenuItemResponse;
  isMobile?: boolean;
  isLastItem?: boolean;
  index?: number;
  isModalOpened?: boolean;
  isLogged?: boolean;
}

const HeaderMenuItem = ({
  item,
  isMobile,
  isLastItem,
  index,
  isModalOpened,
  isLogged,
}: IHeaderMenuItemProps): JSX.Element => {
  const router = useRouter();
  const appContext = useAppContext();
  const filtersContext = useContext(FiltersContext);
  const hasChildren = item?.children && item?.children?.length > 0;
  const [showAccordion, setShowAccordion] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLLIElement>();
  const arrowRef = useRef<LegacyRef<HTMLDivElement>>();

  useEffect(() => {
    if (isModalOpened) {
      setShowAccordion(false);
    }
  }, [isModalOpened]);

  const onClickHandler = (): void => {
    window.innerWidth >= DESKTOP_SIZE
      ? setShowDropdown(!showDropdown)
      : setShowAccordion(!showAccordion);
  };

  const onMouseEnter = (): void => {
    window.innerWidth >= DESKTOP_SIZE && setShowDropdown(true);
  };

  const onMouseLeave = (): void => {
    window.innerWidth >= DESKTOP_SIZE && setShowDropdown(false);
  };

  useEffect(() => {
    const handleRouteChange = (): void => {
      window.innerWidth >= DESKTOP_SIZE
        ? setShowDropdown(false)
        : appContext?.setShowHeaderMenuMobile(false);

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "auto",
        });
      }, 100);
    };
    // Left as expect-error because initially appContext is null
    // @ts-expect-error
    if (appContext?.setShowHeaderMenuMobile) {
      router.events.on("routeChangeComplete", handleRouteChange);
    } else {
      router.events.off("routeChangeComplete", handleRouteChange);
    }

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [appContext?.setShowHeaderMenuMobile]);

  useEffect(() => {
    // Logic for closing the opened menus upon pressing escape (they are not closing with tabs)
    const handleUserKeyPress = (e: Event): void => {
      onEscapeDown(e, () => setShowDropdown(false));
    };
    window.addEventListener("keydown", (e) => handleUserKeyPress(e));
    return () => {
      window.removeEventListener("keydown", (e) => handleUserKeyPress(e));
    };
  });

  const onFindYourTripClick = useCallback(() => {
    filtersContext?.removeExperience();
    filtersContext?.setGlobalSearch((prevState) => {
      return {
        ...prevState,
        value: true,
      };
    });
    filtersContext?.openFilterModal();
    appContext?.setShowHeaderMenuMobile(false);
  }, []);

  const aria: any = { role: "menuitem" };
  if (hasChildren) {
    aria["aria-haspopup"] = hasChildren ? "true" : "false";
    aria["aria-expanded"] = showDropdown ? "true" : "false";
  }

  return (
    <li
      className="flex cursor-default flex-col px-5 desktop:relative desktop:p-0"
      ref={ref as LegacyRef<HTMLLIElement>}
      onMouseEnter={onMouseEnter}
      onFocus={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tabIndex={item.children?.length ? 0 : undefined}
      onKeyDown={
        isMobile
          ? item.children?.length
            ? (e) => onEnterDown(e, onClickHandler)
            : undefined
          : undefined
      }
      {...aria}
    >
      <ConditionalWrapper
        condition={!hasChildren}
        wrapper={(children) => {
          return item.url !== RESERVED_URLS.FIND_TRIP ? (
            <Link href={slashBeforeUrlAdder(item.url)}>{children}</Link>
          ) : (
            <div
              className="cursor-pointer"
              tabIndex={0}
              onClick={onFindYourTripClick}
              onKeyDown={(e) => onEnterDown(e, onFindYourTripClick)}
            >
              {children}
            </div>
          );
        }}
      >
        <div
          className="my-3 flex flex-row items-center justify-between desktop:m-0"
          onClick={hasChildren ? onClickHandler : undefined}
          //aria-haspopup={hasChildren ? "true" : "false"}
          //aria-expanded={showDropdown ? "true" : "false"}
          //role={hasChildren ? "menuitem" : "link"}
        >
          <div
            // Solution with desktop and desktopLarge to handle on large scale when navbar is overcrowded.
            className={`whitespace-nowrap text-p font-medium text-black desktop:p-2 desktop:font-normal desktopLarge:p-3 ${
              showDropdown ? "text-orange-100" : ""
            }`}
          >
            {item.title}
          </div>
          {hasChildren && isMobile && (
            <Arrow direction={showAccordion ? "up" : "down"} />
          )}
        </div>
      </ConditionalWrapper>
      {hasChildren && (showAccordion || showDropdown) && (
        <HeaderSubmenu
          index={index}
          arrowRef={arrowRef as LegacyRef<HTMLDivElement>}
          showAccordion={showAccordion}
          showDropdown={showDropdown}
          item={item}
          isMobile={appContext?.isMobile || appContext?.isTablet}
        />
      )}
      {isMobile && (!isLastItem || (isLastItem && isLogged)) && (
        <Divider variant="mobile" key={`hr${item.id}`} />
      )}
    </li>
  );
};

export default HeaderMenuItem;
