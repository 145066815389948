// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class MediaAssets {
  static assets: any = {};

  static loadAssets(data: any): any {
    if (data !== null && data !== undefined && Object.keys(data).length > 0)
      data.forEach((e: any) => {
        const texts = e.Name.replace(/\W+/g, " ")
          .split(/ |\B(?=[A-Z])/)
          .map((word: string) => word.toLowerCase())
          .join("_");
        this.assets[texts] = e.Media?.url;
      });
  }

  static getAllAssets(): any {
    return this.assets;
  }

  static getAsset(key: string): any {
    // eslint-disable-next-line no-prototype-builtins
    return this.getAllAssets().hasOwnProperty(key)
      ? this.assets[key]
      : this.assets.no_image_found;
  }
}
