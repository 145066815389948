import dateRangePickerConfigNamu from "@components/utils/dateRangePickerConfigNamu";
import { IUseCalendarReturn } from "@hooks/useCalendar";
import dayjs from "dayjs";
import { FiltersContext, IFiltersContext } from "providers/FiltersProvider";
import { Context, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface IHorizontalCalendar
  extends Pick<
    IUseCalendarReturn,
    | "monthsShown"
    | "setStartDate"
    | "startDateState"
    | "endDateState"
    | "setEndDate"
  > {
  isMobilePopUp?: boolean;
  observe?: string;
}

const HorizontalCalendar = ({
  monthsShown,
  startDateState,
  setStartDate,
  endDateState,
  setEndDate,
}: IHorizontalCalendar): JSX.Element => {
  const { changeCalendarSelection } = useContext<IFiltersContext>(
    FiltersContext as Context<IFiltersContext>
  );

  const onDatesUpdate = (dates: [Date | null, Date | null]): void => {
    const [startDate, endDate] = dates.map((dt) => (dt ? dayjs(dt) : null));

    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate && endDate) {
      changeCalendarSelection(true);
    }
  };

  return (
    <DatePicker
      {...dateRangePickerConfigNamu}
      monthsShown={monthsShown}
      startDate={startDateState?.toDate()}
      endDate={endDateState?.toDate()}
      onChange={onDatesUpdate}
    />
  );
};

export default HorizontalCalendar;
