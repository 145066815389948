interface IArrow {
  direction?: "up" | "down" | "left" | "right";
  size?: "small" | "medium" | "big";
  color?: "midgray" | "lightblue" | "orange";
  thickness?: "thin" | "medium" | "bold";
}

interface IMapString<T> {
  [key: string]: T;
}

const ARROW_SIZE: IMapString<{
  width: number;
  height: number;
}> = {
  small: { width: 8, height: 5 },
  medium: { width: 12, height: 8 },
  big: { width: 15, height: 8 },
};

const DIRECTION_CLASS: IMapString<string> = {
  up: "rotate-180",
  down: "",
  right: "-rotate-90",
  left: "rotate-90",
};

const COLOR_SCHEME: IMapString<string> = {
  midgray: "stroke-midgray",
  lightblue: "stroke-lightblue",
  orange: "stroke-orange",
};

const THICKNESS: IMapString<number> = {
  thin: 1,
  medium: 1.5,
  bold: 2,
};

const Arrow = ({ direction, size, color, thickness }: IArrow): JSX.Element => {
  return (
    <div
      className={`h-fit w-fit self-center ${
        DIRECTION_CLASS[direction ?? "down"]
      } ${COLOR_SCHEME[color ?? "midgray"]}`}
    >
      <svg
        width={ARROW_SIZE[size ?? "big"].width}
        height={ARROW_SIZE[size ?? "big"].height ?? 8}
        viewBox="0 0 15 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.68217 0.75L7.46806 6.75L13.6822 0.749999"
          strokeWidth={THICKNESS[thickness ?? "medium"]}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default Arrow;
