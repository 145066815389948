import { LegacyRef, useEffect, useRef } from "react";

const useFocus = (): LegacyRef<HTMLDivElement> => {
  const focusRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (focusRef?.current) {
      focusRef?.current.focus();
    }
  }, []);

  return focusRef as LegacyRef<HTMLDivElement>;
};

export default useFocus;
